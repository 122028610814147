import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Humanbreadcreumb from "../components/resources/resource-breadcumb";

import Humancontentpage from "../components/resources/human-content-long-new";

// markup
const HumanheartfulnessPage = () => {
  return (
    <div>
      <Seo title="Humans Of Heartfulness" />

      <Layout isSticky>
        <section>
          <Humanbreadcreumb />
        </section>

        <section>
          <Humancontentpage />
        </section>
      </Layout>
    </div>
  );
};

export default HumanheartfulnessPage;
